<template>
  <div class="auth-wrapper">
    <div class="container">
      <div class="row auth-container">
        <!-- <div class="col-lg-6 col-md-12 auth-container-left">
          <img
            src="../assets/images/logo/logo_register.svg"
            class="img-fluid"
          />
        </div> -->
        <div class="col-md-12 maintenance">
          <div class="auth-card">
            <div class="card-body">
              <img
                class="img-fluid"
                src="../assets/images/logo/logo_landing.svg"
              />
              <h1>
                {{ $t("utils.oops") }}
                {{ $t("utils.current_maintenance_time") }}
              </h1>
              <p>{{ message }}</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Footer from "../components/layouts/Footer";

export default {
  name: "PageMaintenance",
  components: { Footer },
  data() {
    return {
      date: new Date(),
    };
  },
  computed: {
    ...mapGetters("auth", ["loggedIn"]),
    message() {
      const msg = this.$route.query.message;
      return msg ? `${msg}.` : this.$t("utils.came_later_after_maintenance");
    },
  },
  metaInfo() {
    return {
      title: this.$t(`menu.${this.$route.name}.title`),
    };
  },
  mounted() {
    this.$store.dispatch("checkEndMaintenanceMode").then(({ check }) => {
      if (check) {
        if (this.loggedIn) this.$router.push({ name: "dashboard" });
        else window.location.href = "/";
      } else {
        if (this.loggedIn) this.$router.push({ name: "deposit" });
        else window.location.href = "/";
      }
    });
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    onNavbarLinkClick() {
      window.$(".navbar-collapse").removeClass("show");
    },
    onNavbarCollapseClick() {
      if (window.$(".navbar-collapse").hasClass("show"))
        window.$(".navbar-collapse").removeClass("show");
      else window.$(".navbar-collapse").addClass("show");
    },
    landingLogout() {
      this.logout(true).then(() => {
        location.reload();
      });
    },
    onTooltipBtn() {
      window.$("body,html").animate(
        {
          scrollTop: 0,
        },
        800
      );
    },
  },
};
</script>

<style scoped src="../assets/landing/css/style.css"></style>
